const variables = {
  colors: {
    base: "yellow",
    background: "#fff",
    blackLight: "#f5f5f5",
    gray: "#727d86",
    silver: "#969fa7",
    whitesmoke: "#f1f4f7",
    highlight: "#20a8ea",
    red: "#f7615f",
    orange: "#ffa22b",
    gradient: "linear-gradient(-45deg,#ffa649,#f7645b,#805ed4)",
  },
  sizes: {
    bioWidth: "290px",
    maxWidth: "1100px",
  },
  sideSpace: {
    small: "20px",
    large: "1.5em",
    contentSmall: "20px",
    contentLarge: "2.5em",
  },
  responsive: {
    small: "500px",
    medium: "768px",
    large: "950px",
  },
}

export default variables
