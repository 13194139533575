import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
// import home from "../svg/footer/home.svg"
// import library from "../svg/footer/library.svg"

const FooterSection = styled.header`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  padding: 0;

  display: none;
  @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    display: block;
  }
`

const FooterTag = styled.div`
  top: 0;
  height: 65px;
  width: 100%;
  padding: 10px 0;
  margin: 0;
  background: #fff;
  z-index: 10;

  .logo {
    height: 40px;
    /* width: 50%; */
    width: 33%;
    cursor: pointer;
  }

  #home {
    fill: ${(props) => props.homeFill};
  }

  #library {
    fill: ${(props) => props.libraryFill};
  }
`

const MobileFooter = ({ isHome, isLibrary }) => {
  let homeFill = "#000"
  let libraryFill = "#000"

  if (isHome) {
    homeFill = "#20a8ea"
    libraryFill = "#000"
  } else if (isLibrary) {
    homeFill = "#000"
    libraryFill = "#20a8ea"
  } else {
    homeFill = "#000"
    libraryFill = "#000"
  }

  return (
    <FooterSection>
      <FooterTag>
        <Link to={`/`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 300"
            className="logo"
            fill={homeFill}
            id="home"
          >
            <path d="M230.54,82.48,155.1,27.56a8.76,8.76,0,0,0-10,0L69.68,82.48a5.81,5.81,0,0,0-2.56,4.67v92.14c0,3.44,3.38,6.23,7.55,6.23H225.55c4.16,0,7.54-2.79,7.54-6.23V87.15A5.82,5.82,0,0,0,230.54,82.48ZM218,173.06H185.94V124.37c0-3.44-3.38-6.23-7.54-6.23H121.82c-4.17,0-7.55,2.79-7.55,6.23v48.69H82.21V90l67.9-49.42L218,90Z" />
            <path d="M50.68,270V212.71h7.58v23.52H88V212.71H95.6V270H88V243H58.26v27Z" />
            <path d="M108.7,249.24q0-11.53,6.41-17.07a19.26,19.26,0,0,1,13-4.61,18.6,18.6,0,0,1,14,5.6q5.43,5.61,5.43,15.49,0,8-2.4,12.6a17.12,17.12,0,0,1-7,7.13,20.39,20.39,0,0,1-10,2.54q-8.7,0-14.08-5.59T108.7,249.24Zm7.23,0q0,8,3.47,11.93a11.59,11.59,0,0,0,17.47,0q3.47-4,3.47-12.15,0-7.69-3.49-11.66a11.61,11.61,0,0,0-17.45,0Q115.94,241.27,115.93,249.24Z" />
            <path d="M159.81,270V228.49h6.29v5.82a14.8,14.8,0,0,1,5.19-4.9,14.61,14.61,0,0,1,7.39-1.85,13.63,13.63,0,0,1,7.56,1.91,10.31,10.31,0,0,1,4.16,5.35q4.92-7.26,12.81-7.26,6.16,0,9.49,3.41T216,241.5V270h-7V243.84a19.43,19.43,0,0,0-.68-6.07,6,6,0,0,0-2.48-3,7.81,7.81,0,0,0-4.22-1.13,9.83,9.83,0,0,0-7.27,2.91q-2.9,2.91-2.89,9.32V270h-7V243q0-4.68-1.72-7t-5.62-2.34a10.23,10.23,0,0,0-5.49,1.56,8.91,8.91,0,0,0-3.66,4.57,25.9,25.9,0,0,0-1.13,8.67V270Z" />
            <path d="M258.85,256.62l7.27.9a17.74,17.74,0,0,1-6.37,9.88q-4.65,3.51-11.88,3.52-9.1,0-14.43-5.61t-5.33-15.72q0-10.47,5.39-16.25a18.21,18.21,0,0,1,14-5.78,17.73,17.73,0,0,1,13.6,5.66q5.26,5.67,5.27,15.94c0,.41,0,1,0,1.87H235.37q.39,6.84,3.87,10.47a11.52,11.52,0,0,0,8.67,3.63,10.71,10.71,0,0,0,6.6-2A13.41,13.41,0,0,0,258.85,256.62Zm-23.09-11.37h23.17q-.46-5.24-2.66-7.85a10.76,10.76,0,0,0-8.71-4.06,11.16,11.16,0,0,0-8.14,3.24A12.81,12.81,0,0,0,235.76,245.25Z" />
          </svg>
        </Link>
        <Link to={`/library`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 300"
            className="logo"
            fill={libraryFill}
            id="library"
          >
            <path d="M234.34,86.64a13,13,0,0,0-10.62-9l-42.28-6.14-18.9-38.23a13.15,13.15,0,0,0-23.58,0L120.05,71.55,77.77,77.69a13.13,13.13,0,0,0-7.28,22.41l30.58,29.76-7.22,42a13.15,13.15,0,0,0,19.08,13.85l37.82-19.84,37.81,19.84a13.15,13.15,0,0,0,19.08-13.85l-7.22-42L231,100.1A13,13,0,0,0,234.34,86.64Zm-45.58,36.2a6.56,6.56,0,0,0-1.9,5.83l7.8,45.39L153.8,152.63a6.54,6.54,0,0,0-6.11,0l-40.86,21.44,7.8-45.4a6.56,6.56,0,0,0-1.9-5.83l-33-32.13,45.65-6.62a6.62,6.62,0,0,0,5-3.6l20.43-41.32,20.43,41.32a6.57,6.57,0,0,0,4.95,3.59l45.66,6.63Z" />
            <path d="M21.56,270V212.71h7.58v50.51H57.35V270Z" />
            <path d="M69.51,220.8v-8.09h7v8.09Zm0,49.18V228.49h7V270Z" />
            <path d="M97.73,270H91.21V212.71h7v20.43a13.91,13.91,0,0,1,11.36-5.58,17.38,17.38,0,0,1,7.25,1.54,14.78,14.78,0,0,1,5.62,4.33,20.82,20.82,0,0,1,3.46,6.74,27.61,27.61,0,0,1,1.25,8.44q0,10.66-5.27,16.48a16.49,16.49,0,0,1-12.66,5.83,13.22,13.22,0,0,1-11.52-6.14Zm-.08-21.06q0,7.47,2,10.78,3.31,5.43,9,5.43a10.09,10.09,0,0,0,8-4c2.25-2.67,3.36-6.65,3.36-11.93s-1.07-9.42-3.22-12a9.82,9.82,0,0,0-7.79-3.86,10.11,10.11,0,0,0-8,4Q97.65,241.34,97.65,248.92Z" />
            <path d="M139.66,270V228.49H146v6.29q2.41-4.41,4.47-5.82a7.79,7.79,0,0,1,4.51-1.4,13.73,13.73,0,0,1,7.23,2.26l-2.42,6.52a10.12,10.12,0,0,0-5.16-1.52,6.65,6.65,0,0,0-4.14,1.39,7.47,7.47,0,0,0-2.62,3.85,27.4,27.4,0,0,0-1.17,8.2V270Z" />
            <path d="M197.45,264.86a26.31,26.31,0,0,1-7.52,4.69,21.8,21.8,0,0,1-7.76,1.37c-4.55,0-8.06-1.12-10.5-3.34A11,11,0,0,1,168,259a11.57,11.57,0,0,1,5-9.61,17.29,17.29,0,0,1,5.06-2.3,49.51,49.51,0,0,1,6.25-1.06,64.26,64.26,0,0,0,12.54-2.42c0-1,0-1.58,0-1.84q0-4.29-2-6.05-2.7-2.38-8-2.38-5,0-7.32,1.73c-1.58,1.16-2.74,3.21-3.5,6.16l-6.87-.94a17,17,0,0,1,3.08-7.13A13.53,13.53,0,0,1,178.5,229a27.66,27.66,0,0,1,9.42-1.46,24.73,24.73,0,0,1,8.63,1.25,11.37,11.37,0,0,1,4.88,3.14,11.11,11.11,0,0,1,2.19,4.79,40,40,0,0,1,.35,6.48v9.37a99.43,99.43,0,0,0,.45,12.41,15.58,15.58,0,0,0,1.78,5h-7.35A15,15,0,0,1,197.45,264.86Zm-.59-15.7a51.77,51.77,0,0,1-11.48,2.65,26.86,26.86,0,0,0-6.14,1.41,6.14,6.14,0,0,0-2.77,2.28,6.21,6.21,0,0,0,1.15,8q2.13,1.88,6.23,1.88a14.46,14.46,0,0,0,7.23-1.78,11,11,0,0,0,4.65-4.86,17.11,17.11,0,0,0,1.13-7Z" />
            <path d="M218.79,270V228.49h6.33v6.29q2.41-4.41,4.47-5.82a7.79,7.79,0,0,1,4.51-1.4,13.73,13.73,0,0,1,7.23,2.26l-2.42,6.52a10.12,10.12,0,0,0-5.16-1.52,6.67,6.67,0,0,0-4.14,1.39,7.59,7.59,0,0,0-2.62,3.85,27.4,27.4,0,0,0-1.17,8.2V270Z" />
            <path d="M249.2,286l-.78-6.6a15.46,15.46,0,0,0,4,.63,7.74,7.74,0,0,0,3.75-.78,6.43,6.43,0,0,0,2.3-2.19,33.55,33.55,0,0,0,2.15-5.24q.19-.58.63-1.71l-15.74-41.57h7.57l8.64,24q1.68,4.56,3,9.61a98.67,98.67,0,0,1,2.89-9.46l8.87-24.18h7l-15.78,42.19a82.87,82.87,0,0,1-3.94,9.41,13.92,13.92,0,0,1-4.3,5.1,10.16,10.16,0,0,1-5.78,1.62A13.88,13.88,0,0,1,249.2,286Z" />
          </svg>
        </Link>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdUzzOqdPv0t_tbprfe2qUFY-ip6ArN07Hcv_3JF4uMkjqLbw/viewform">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 300"
            className="logo"
            fill="#000"
            id="contact"
          >
            <g>
              <g>
                <path
                  class="st0"
                  d="M156.9,72.7l47.6-28.2l-88.7,92.1l-44.1-13.5L156.9,72.7 M153.2,66.4L60.9,121c-1.2,0.7-1.9,2.1-1.8,3.5
			s1.2,2.6,2.5,3l52.7,16.1l23.3,42.3c0,0,0,0.1,0.1,0.1c0.4,0.6,1,1.2,1.7,1.5c0.5,0.2,0.9,0.3,1.4,0.3c0.9,0,1.8-0.4,2.5-1
			l26.7-26.1l50.3,15.4c0.3,0.1,0.7,0.2,1.1,0.2c0.8,0,1.5-0.2,2.2-0.7c0.9-0.7,1.5-1.8,1.5-2.9V30.3c0-1.3-0.7-2.5-1.8-3.1
			s-2.5-0.6-3.6,0L153.2,66.4 M137.9,145.9c-0.4,0.6-0.6,1.3-0.6,2.1v22.2l-15.9-28.9l70.3-72.9L137.9,145.9z M144.5,175.7v-22.8
			l17.8,5.4L144.5,175.7z M217.9,167.8L146.6,146l71.2-102.8L217.9,167.8L217.9,167.8z"
                />
              </g>
            </g>
            <g class="st1">
              <path
                d="M66.5,249.7l7.2,1.8c-1.5,5.9-4.2,10.5-8.2,13.6c-3.9,3.1-8.8,4.7-14.5,4.7c-5.9,0-10.7-1.2-14.4-3.6s-6.5-5.9-8.4-10.4
		c-1.9-4.6-2.9-9.4-2.9-14.7c0-5.7,1.1-10.7,3.3-14.9c2.2-4.2,5.3-7.5,9.3-9.7s8.4-3.3,13.3-3.3c5.5,0,10.1,1.4,13.8,4.2
		c3.7,2.8,6.3,6.7,7.8,11.8l-7.1,1.7c-1.3-4-3.1-6.9-5.5-8.7c-2.4-1.8-5.4-2.7-9.1-2.7c-4.2,0-7.7,1-10.5,3c-2.8,2-4.8,4.7-6,8.1
		c-1.1,3.4-1.7,6.9-1.7,10.5c0,4.7,0.7,8.7,2,12.2c1.4,3.5,3.5,6.1,6.3,7.8c2.9,1.7,6,2.6,9.3,2.6c4.1,0,7.5-1.2,10.3-3.5
		C63.6,257.8,65.5,254.3,66.5,249.7z"
              />
              <path
                d="M79.3,249.1c0-7.3,2-12.8,6.1-16.3c3.4-2.9,7.6-4.4,12.5-4.4c5.4,0,9.9,1.8,13.4,5.4c3.5,3.6,5.2,8.5,5.2,14.8
		c0,5.1-0.8,9.1-2.3,12c-1.5,2.9-3.8,5.2-6.7,6.8c-2.9,1.6-6.1,2.4-9.6,2.4c-5.5,0-10-1.8-13.5-5.3C81,260.9,79.3,255.8,79.3,249.1z
		 M86.2,249.1c0,5.1,1.1,8.9,3.3,11.4c2.2,2.5,5,3.8,8.4,3.8c3.3,0,6.1-1.3,8.3-3.8c2.2-2.5,3.3-6.4,3.3-11.6
		c0-4.9-1.1-8.6-3.3-11.1c-2.2-2.5-5-3.8-8.3-3.8c-3.4,0-6.1,1.3-8.4,3.8C87.3,240.2,86.2,244,86.2,249.1z"
              />
              <path
                d="M124.3,268.9v-39.6h6v5.6c2.9-4.4,7.1-6.5,12.6-6.5c2.4,0,4.6,0.4,6.6,1.3c2,0.9,3.5,2,4.5,3.4s1.7,3,2.1,5
		c0.2,1.2,0.4,3.4,0.4,6.5v24.4h-6.7v-24.1c0-2.7-0.3-4.8-0.8-6.1c-0.5-1.4-1.4-2.4-2.8-3.2s-2.9-1.2-4.7-1.2
		c-2.9,0-5.3,0.9-7.4,2.7c-2.1,1.8-3.1,5.3-3.1,10.3v21.6H124.3z"
              />
              <path
                d="M181.5,262.9l1,5.9c-1.9,0.4-3.6,0.6-5.1,0.6c-2.4,0-4.3-0.4-5.7-1.2c-1.3-0.8-2.3-1.8-2.8-3c-0.5-1.3-0.8-3.9-0.8-7.9
		v-22.8h-4.9v-5.2h4.9v-9.8l6.7-4v13.8h6.8v5.2h-6.8v23.2c0,1.9,0.1,3.1,0.4,3.7c0.2,0.5,0.6,1,1.2,1.3s1.3,0.5,2.3,0.5
		C179.3,263.2,180.3,263.1,181.5,262.9z"
              />
              <path
                d="M213.9,264c-2.5,2.1-4.9,3.6-7.2,4.5c-2.3,0.9-4.8,1.3-7.4,1.3c-4.4,0-7.7-1.1-10-3.2c-2.3-2.1-3.5-4.8-3.5-8.2
		c0-1.9,0.4-3.7,1.3-5.3c0.9-1.6,2-2.9,3.5-3.9c1.4-1,3-1.7,4.8-2.2c1.3-0.3,3.3-0.7,6-1c5.4-0.6,9.4-1.4,12-2.3c0-0.9,0-1.5,0-1.8
		c0-2.7-0.6-4.7-1.9-5.8c-1.7-1.5-4.3-2.3-7.7-2.3c-3.2,0-5.5,0.6-7,1.7c-1.5,1.1-2.6,3.1-3.3,5.9l-6.6-0.9c0.6-2.8,1.6-5.1,2.9-6.8
		c1.4-1.7,3.3-3.1,5.9-4c2.6-0.9,5.6-1.4,9-1.4c3.4,0,6.1,0.4,8.2,1.2c2.1,0.8,3.7,1.8,4.7,3s1.7,2.7,2.1,4.6
		c0.2,1.1,0.3,3.2,0.3,6.2v9c0,6.2,0.1,10.2,0.4,11.9s0.9,3.2,1.7,4.8h-7C214.6,267.5,214.1,265.9,213.9,264z M213.4,249
		c-2.4,1-6.1,1.8-11,2.5c-2.8,0.4-4.7,0.8-5.9,1.3c-1.1,0.5-2,1.2-2.7,2.2c-0.6,1-0.9,2-0.9,3.2c0,1.8,0.7,3.3,2,4.5
		c1.4,1.2,3.3,1.8,6,1.8c2.6,0,4.9-0.6,6.9-1.7c2-1.1,3.5-2.7,4.4-4.6c0.7-1.5,1.1-3.8,1.1-6.7V249z"
              />
              <path
                d="M256.5,254.4l6.6,0.9c-0.7,4.6-2.6,8.1-5.5,10.7c-3,2.6-6.6,3.9-11,3.9c-5.4,0-9.8-1.8-13.1-5.3s-4.9-8.6-4.9-15.2
		c0-4.3,0.7-8,2.1-11.2c1.4-3.2,3.6-5.6,6.5-7.2c2.9-1.6,6.1-2.4,9.5-2.4c4.3,0,7.8,1.1,10.6,3.3s4.5,5.3,5.3,9.3l-6.5,1
		c-0.6-2.7-1.7-4.7-3.3-6c-1.6-1.3-3.5-2-5.7-2c-3.4,0-6.1,1.2-8.2,3.6c-2.1,2.4-3.2,6.3-3.2,11.5c0,5.3,1,9.2,3.1,11.6
		c2,2.4,4.7,3.6,8,3.6c2.6,0,4.8-0.8,6.6-2.4C254.9,260.2,256,257.8,256.5,254.4z"
              />
              <path
                d="M283.5,262.9l1,5.9c-1.9,0.4-3.6,0.6-5.1,0.6c-2.4,0-4.3-0.4-5.7-1.2c-1.3-0.8-2.3-1.8-2.8-3c-0.5-1.3-0.8-3.9-0.8-7.9
		v-22.8h-4.9v-5.2h4.9v-9.8l6.7-4v13.8h6.8v5.2h-6.8v23.2c0,1.9,0.1,3.1,0.4,3.7c0.2,0.5,0.6,1,1.2,1.3s1.3,0.5,2.3,0.5
		C281.3,263.2,282.3,263.1,283.5,262.9z"
              />
            </g>
          </svg>
        </a>
      </FooterTag>
    </FooterSection>
  )
}

export default MobileFooter
