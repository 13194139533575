import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import svgLogo from "../svg/logo.svg"

const HeaderSection = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
`

const HeaderTag = styled.div`
  position: sticky; // Promotion Header 表示中
  top: 0;
  width: 100%;
  height: 55px;
  padding: 10px 0;
  background: #fff;
  z-index: 10;
  /* border-bottom: solid 1px ${(props) => props.theme.colors.blackLight}; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);

  /* @media screen and (max-width: ${(props) =>
    props.theme.responsive.small}) { */

  padding: 2px;
  box-shadow: 0 0 0;

  &:after {
    content: "englissu.com";
    font-size: 1.3em;
    font-family: "Josefin Sans", serif;
    color: #000;
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* } */
  }
`

const PromotionHeader = styled.a`
  display: block;
  width: 100%;
  color: #fff;
  /* height: 40px; */
  font-size: 0.9em;
  /* line-height: 28px; */
  text-align: center;
  padding: 0.5em 1em;

  margin-bottom: 0;
  background-image: linear-gradient(-45deg, #f093fb 0%, #f5576c 100%);
  z-index: 100;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); */
  cursor: pointer;

  animation: gradation-wave 1s ease infinite;

  @keyframes gradation-wave {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`

const HeaderInner = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  /* align-items: center; */

  h1,
  h3 {
    display: inline-block;
    height: 44px;
    @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
      height: 35px;
    }
  }
  .logo {
    display: block;
    width: 165px;
    height: 44px;

    @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
      /* height: 35px; */
      display: none;
    }
  }
  .logo-link {
    display: block;
  }

  .logoWrapper {
    padding-top: 4px;
    @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
      display: block;
      margin: 0 auto;
    }
    &:hover {
      opacity: 0.6;
      transition: 0.2s;
    }
  }
`

const CategoryNav = styled.ul`
  display: inline;
  line-height: 53px;
  font-size: 14px;

  @media screen and (max-width: 955px) {
    line-height: 26px;
  }

  .category-link {
    color: #333;
    display: inline;
    padding: 0 10px;
    display: inline-block;
    &:hover {
      color: ${(props) => props.theme.colors.highlight};
      text-decoration: none;
      transition: 0.2s;
      border-bottom: 2px solid ${(props) => props.theme.colors.highlight};
      :focus {
        outline: none;
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    display: none;
  }
`

const HeaderWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${(props) => props.theme.sideSpace.large};
  @media screen and (max-width: ${(props) => props.theme.responsive.large}) {
    max-width: 760px;
  }
  @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    padding: 0 ${(props) => props.theme.sideSpace.small};
  }
`

const Header = ({ title, location }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const logoLink = (
    <Link to={`/`} className="logo-link">
      <img className="logo" src={svgLogo} alt={title} />
    </Link>
  )

  // トップページではヘッダーロゴをh1に、記事ではh3に
  let headerLogo
  if (location.pathname === rootPath) {
    headerLogo = <h1>{logoLink}</h1>
  } else {
    headerLogo = <h3>{logoLink}</h3>
  }

  return (
    <HeaderSection>
      <PromotionHeader href="https://af.moshimo.com/af/c/click?a_id=1064904&p_id=170&pc_id=185&pl_id=4062&url=https%3A%2F%2Fwww.amazon.co.jp%2Fhko%2Fdeals">
        今だけお得！ Kindle の日替わりセールをチェック 👉
      </PromotionHeader>

      <HeaderTag>
        <HeaderWrapper>
          <HeaderInner>
            {/* <div className="logoWrapper">{headerLogo}</div> */}
            {/* <CategoryNav>
              <Link to={`/`} className="category-link">
                New
              </Link>

              <Link to={`/category/teacher`} className="category-link">
                教員
              </Link>

              <Link to={`/category/student`} className="category-link">
                受験生
              </Link>

              <Link to={`/category/parents`} className="category-link">
                保護者
              </Link>

              <Link to={`/category/applicant`} className="category-link">
                教員志望者
              </Link>

              <Link to={`/category/learner`} className="category-link">
                英語学習者
              </Link>

              <Link to={`/category/tourist`} className="category-link">
                旅行者
              </Link>

              <Link to={`/_profile`} className="category-link">
                サイト概要
              </Link>

              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdUzzOqdPv0t_tbprfe2qUFY-ip6ArN07Hcv_3JF4uMkjqLbw/viewform?usp=sf_link"
                className="category-link"
              >
                お問い合わせ
              </a>
            </CategoryNav> */}
          </HeaderInner>
        </HeaderWrapper>
      </HeaderTag>
    </HeaderSection>
  )
}

export default Header
