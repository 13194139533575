import React from "react"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "../styles/global"
import theme from "../styles/theme"
import MobileNavigator from "../components/MobileNavigator"
import MobileFooter from "../components/MobileFooter"
import Header from "../components/Header"
import Footer from "../components/Footer"
// import Bio from "./Bio"
import ContentWrapper from "../components/ContentWrapper"
import styled from "styled-components"

const Content = styled.div`
  margin-top: 2em;
  display: flex;
  min-height: 85vh;
  align-items: flex-start;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    display: block;
  }
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    /* margin-top: 1em; */
    margin-top: 0;
  }
`

const MainWrapper = styled.div`
  width: ${props => props.theme.responsive.large};
  margin: 0 auto;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    width: 100%;
  }
`

class Layout extends React.PureComponent {
  render() {
    const { location, title, children } = this.props

    const rootPath = `${__PATH_PREFIX__}/`
    const isHome = location.pathname === rootPath ? true : false

    return (
      <ThemeProvider theme={theme}>
        <div className="siteRoot">
          {/* <MobileNavigator /> */}
          <Header title={title} location={location} />
          <ContentWrapper isHome={isHome}>
            <Content>
              <MainWrapper>
                <main>{children}</main>
              </MainWrapper>
            </Content>
            {/* <Bio isBio={isBio} /> */}
          </ContentWrapper>
          <Footer />
          <MobileFooter isHome={isHome} />
          <GlobalStyle />
        </div>
      </ThemeProvider>
    )
  }
}

export default Layout
