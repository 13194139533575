import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ContentWrapper from "../components/ContentWrapper"

const FooterContent = styled.footer`
  padding: 0.1em 0;
`

const FooterInner = styled.div`
  /* margin-top: 3em; */
  margin-top: 1em;
  text-align: center;
  padding: 1.5em;
  border-top: solid 1px ${props => props.theme.colors.blackLight};
  color: ${props => props.theme.colors.gray};
  font-size: 14px;
  a {
    padding: 0 0.5em;
    color: ${props => props.theme.colors.gray};
    text-decoration: underline;
  }

  span {
    display: inline-block;
  }

  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    margin-bottom: 1em;
  }
`

const Footer = () => {
  return (
    <FooterContent>
      <ContentWrapper>
        <FooterInner>
          <div>
            <div>
              <Link to="/">englissu.com</Link>
            </div>
            <span>
              <Link to="/contact-form">お問い合わせ</Link>
              <Link to="/_law">免責事項</Link>
            </span>
            <span>
              <Link to="/_privacy-policy">プライバシーポリシー</Link>
            </span>
            <div>
              ©︎{new Date().getFullYear()}, englissu.com All rights served.
            </div>
          </div>
        </FooterInner>
      </ContentWrapper>
    </FooterContent>
  )
}

export default Footer
