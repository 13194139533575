import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import svgLogo from "../svg/logo-white.svg"

const MenuWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    display: block;
  }
`

const MenuContentsWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 70vw;
  height: 100vh;
  background: #2c2c2c;
  color: #fff;
  box-shadow: 0 2px 4px #000;
  font-weight: 600;

  &.hidden {
    display: none;
  }

  .logo {
    position: absolute;
    left: 0%;
    bottom: 5%;
    display: block;
    width: 330px;
    height: 88px;
    fill: #fff;
  }
`

const MenuInner = styled.ul`
  position: relative;
  line-height: 2.5em;
  margin-top: 1em;
  padding-left: 2em;
  font-size: 16px;

  .logo-link {
    display: block;
  }

  .category-link {
    color: #fff;
    display: inline;
    padding: 0;
  }

  .site-link {
    color: #fff;
    display: inline;
    padding: 0;
  }

  h5 {
    line-height: 2em;
    padding: 1em;
    text-align: center;
    letter-spacing: 0.3em;
  }

  .closeIcon {
    position: absolute;
    font-size: 1.5em;
    top: 0;
    right: 5%;
    z-index: 200;
    cursor: pointer;
  }
`

const MobileMenuIcon = styled.div`
  position: fixed;
  top: 12.5px;
  /* top: 40px; */
  left: 12.5px;
  z-index: 100;
  display: inline-block;
  cursor: pointer;
  line-height: 44px;
  margin: auto 0;

  .bar1,
  .bar2,
  .bar3 {
    width: 22px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
    transition: 0.4s;
  }
  /*
  .change .bar1 {
    transform: rotate(-45deg);
    translate: (-9px, 6px);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    transform: rotate(45deg);
    translate: (-8px, -8px);
  } */
`

class MobileNavigator extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isHidden: true }
    this.closeNavMenu = this.closeNavMenu.bind(this)
    this.openNavMenu = this.openNavMenu.bind(this)
  }

  closeNavMenu() {
    this.setState({
      isHidden: true,
    })
  }

  openNavMenu() {
    this.setState({
      isHidden: false,
    })
  }

  render() {
    return (
      <MenuWrapper>
        <MobileMenuIcon onClick={this.openNavMenu}>
          <div className="bar1" />
          <div className="bar2" />
          <div className="bar3" />
        </MobileMenuIcon>
        <MenuContentsWrapper className={this.state.isHidden ? "hidden" : ""}>
          <MenuInner>
            <div className="closeIcon" onClick={this.closeNavMenu}>
              &#x2715;
            </div>
            <li>
              <Link to={`/`} className="category-link">
                NEW
              </Link>
            </li>
            <li>
              <Link to={`/category/teacher`} className="category-link">
                教員
              </Link>
            </li>
            <li>
              <Link to={`/category/student`} className="category-link">
                受験生
              </Link>
            </li>
            <li>
              <Link to={`/category/parents`} className="category-link">
                保護者
              </Link>
            </li>
            <li>
              <Link to={`/category/applicant`} className="category-link">
                教員志望者
              </Link>
            </li>
            <li>
              <Link to={`/category/learner`} className="category-link">
                英語学習者
              </Link>
            </li>
            <li>
              <Link to={`/category/tourist`} className="category-link">
                旅行者
              </Link>
            </li>
            <li>
              <Link to={`/_profile`} className="site-link">
                イングリッス.comについて
              </Link>
            </li>
            <li>
              <Link to={`/contact-form`} className="site-link">
                お問い合わせ
              </Link>
            </li>
          </MenuInner>

          <img className="logo" src={svgLogo} alt="" />
        </MenuContentsWrapper>
      </MenuWrapper>
    )
  }
}

export default MobileNavigator
