import React from "react"
import styled from "styled-components"

const IndexContent = styled.div`
  /* max-width: ${props => props.theme.sizes.maxWidth}; */
  max-width: 760px;
  margin: 0 auto;
  padding: 0 ${props => props.theme.sideSpace.large};
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    max-width: 760px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    /* padding: 0 ${props => props.theme.sideSpace.small}; */
    padding: 0 ${props => props.padding};
  }
`

const ContentWrapper = ({ children, isHome }) => {
  let padding = isHome ? "0" : "20px";
  return <IndexContent padding={padding} >{children}</IndexContent>
}

export default ContentWrapper
